import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Image from "./img/networking.jpg";
// import Image from "./img/448ab3.png";
import NavBar from "./navbar/navbar";
import Contact from "./contact/Contact";
import Fotr from "./Footer/footer";
import Modal_ from "./Modals/Modal_";
import FormHora from "./agendamiento/formhora.js";
import { HomeSectionA } from "./Homesections/sectiona";
import { UserContext } from "./login/login-context";

const Home = () => {
  const [isExpanded, setisExpanded] = useState(false);

  const expandir = () => {
    setisExpanded(true);
  };
  const [txt, setTXT] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [respuestaT, setRespuestaT] = useState({});

  const navigate = useNavigate();
  const handleModalAgendar = (valuemodal)=>{
    setModalAgendar(valuemodal)
  }
  const [modalagendar, setModalAgendar]= useState(false)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  useEffect(() => {
    if (user && user.logged_in) {
      if (user.is_admin) {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    }
  }, [user]);

    const ClickSend = () => {
    // Datos a enviar en la solicitud POST
    const datetime = new Date();
    axios
      .post("/api/clickbusqueda", datetime)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
      
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
  };

  // const enviarFormulario = () => {
  //   // Datos a enviar en la solicitud POST
  //   const formData = {
  //     txt: txt,
  //   };
  //   axios
  //     .post("/api/process", formData)
  //     .then((response) => {
  //       // Manejar la respuesta exitosa aquí
  //       console.log("Respuesta:", response.data);
  //       setRespuestaT(response.data);
  //     })
  //     .catch((error) => {
  //       // Manejar errores aquí
  //       console.error("Error:", error);
  //     });
  // };

const [color, setColor] = useState('#98c1d8'); // Color inicial de Elemento2
const [color2, setColor2] = useState('#e0fbfb'); // Color inicial de Elemento2
useEffect(() => {
    const checkOverlap = () => {
      const elemento1 = document.querySelector('.section1a').getBoundingClientRect();
      const sectionelemento2 = document.querySelector('.sectionbuton').getBoundingClientRect();

      // Comprobar si hay superposición entre Elemento1 y sectionelemento2
      const isOverlapping =
        elemento1.left < sectionelemento2.right &&
        elemento1.right > sectionelemento2.left &&
        elemento1.top < sectionelemento2.bottom &&
        elemento1.bottom > sectionelemento2.top;

      if (isOverlapping) {
        setColor('#e0fbfb');
         setColor2('#98c1d8'); // Cambia el color si hay superposición
      } else {
        setColor('#98c1d8');
        setColor2('#e0fbfb'); // Vuelve al color original si no hay superposición
      }
    };


    checkOverlap(); // Verificar en el montaje inicial
    window.addEventListener('scroll', checkOverlap);
    window.addEventListener('resize', checkOverlap);

    // Limpiar los event listeners cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', checkOverlap);
      window.removeEventListener('resize', checkOverlap);
    };
  }, []);
      useEffect(()=>{
      setModalAgendar(modalagendar),[modalagendar]
    })
  
  useEffect(() => {
    if (Object.keys(respuestaT) != 0) {
      navigate("/psicologos", { state: { query: respuestaT } });
    }
  }, [respuestaT]);

  const params = useParams();
  const openModalProps = {
    loginModalOpen: params.type == "login" ? true : false,
  };

  return (
    <div>
      <NavBar {...openModalProps} />

      <section className={`elemento ${isExpanded ? "expandedmobile" : ""}`}>
        <div className="centerin">
          {/* <h1 className="fuente1">¡Todo empieza con un buen Match! </h1> */}
          <h1 className="fuente1" style={{ marginTop: "4%" }}>
            {/* Porque un buen match es lo más importante */}
            Todo empieza con un buen
          </h1>
          <div className="subsection">
            <div className="div-izquierdo ">
              <p className="fuente2">
                Te ayudamos a encontrar un psicólogo o psicóloga ideal para tu
                proceso. Solo necesitamos que nos cuentes:
              </p>
 

              <article className="artichome">
                <h2
                  style={{ textAlign: "center", margin: "3% 0% 1% 0%" }}
                  className="fuente2"
                >
                  ¿Por qué estás buscando terapia?
                </h2>
                <textarea
                  id="mensaje"
                  name="mensaje"
                  className={`caja-texto ${isExpanded ? "cajaexpanded" : ""}`}
                  placeholder="...Acá te leemos..."
                  value={txt}
                  onChange={(e) => setTXT(e.target.value)}
                  onFocus={expandir}
                ></textarea>
                {/* <div className="fuente2">{respuestaT}</div> */}
                <div className="divboton">
                  {/* <a href="#">
                    <button
                      className="boton-ovalado"
                      onClick={enviarFormulario}
                      disabled={txt.length < 1}
                    >
                      <b>Encuentra</b>{" "}
                      <i className="fa-solid fa-magnifying-glass"></i>{" "}
                    </button>
                  </a> */}
                  <a href="#" data-toggle="modal" data-target="#agendarmodal" onClick={()=>{handleModalAgendar(true),ClickSend()}}>
          <button
            className={`button-login profilebutton`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
             disabled={txt.length < 1}  
          >
            <div>Buscar Profesional</div>
            <div>
              <i
                className={`fa-solid fa-plus`}
                style={{ fontSize: "14px", height: "100%" }}
              ></i>
            </div>
          </button>
        </a>
                </div>
                <p
                  className="message"
                  style={{ marginTop: "25px", textAlign: "center" }}
                >
                  <a
                    href="/ayuda"
                    className="boton-enlace"
                    style={{ textShadow: "2px 2px 4px #a0a0a0"}}
                  >
                    <b>Ayúdame a encontrar</b>{" "}
                    <i className="fa-solid fa-circle-question"></i>
                  </a>
                </p>
              </article>
              {/* <CommentBox /> */}
                
            </div>

            <div className="div-derecho ">
              <img src={Image} className="imglnd" alt="red de psicologos" />
            </div>
          </div>
          <div>
            {/* <iframe
        title="google map"
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.9915177611983!2d-70.61073392359138!3d-33.42346549613178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cfc8869cc193%3A0x21249a7338ca3283!2sEntrevinculos.cl%20%7C%20Psicoterapia%20de%20Pareja%2C%20Familia%2C%20Infanto-juvenil%20y%20Adultos!5e0!3m2!1ses!2scl!4v1723915512770!5m2!1ses!2scl"
        width="20%"
        height="200px"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}
          </div>
        </div>
      </section>
      <div className="section1a" id="section1a">
        <HomeSectionA />
      </div>
      <div >
        <Modal_ isOpen={modalagendar} id="agendarmodal">
       {FormHora(handleModalAgendar,txt)}
      </Modal_>
      </div>
            
      <Contact  color={color} color2={color2} />
      <Fotr />
    </div>
  );
};
export default Home;
